// "use client"

import { useEffect, useState } from "react"

import SearchBar from "../search/searchbar"
import getBooleanSearch from "../../lib/boolean-search"
import getJournalPublications from "../../lib/journal-publications"
import sortPublications from "../../lib/sort-publications"
import getTopAuthors from "../../lib/top-authors"
import getTopJournals from "../../lib/top-journals"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//import BlueButton from "../components/link/blue-button"
import JournalFilter from "./journal-filter"
import Publications from "./publications"
import SortOrder from "./sortby"
import * as React from "react"

import {
  faArrowDownShortWide,
  faArrowUpShortWide,
} from "@fortawesome/free-solid-svg-icons"
//import axios from "axios"
import BaseRow from "../base-row"

import VCenterRow from "../v-center-row"
import ThreeQuarterLayout from "../../layouts/three-quarter-layout"

import { graphql } from "gatsby"
import ArticleDiv from "../article-div"
import HCenterRow from "../h-center-row"
import ToggleSwitch from "../link/toggle-switch"
import Pagination from "../pagination"
import AuthorFilter from "./author-filter"
import BaseLayout from "../../layouts/base-layout"
import Seo from "../../layouts/seo"
import getAuthorPublications from "../../lib/author-publications"
import IDataPageProps from "../../interfaces/data-page-props"
import PageTitle from "../page-title"

const EMPTY_QUERY = ""

const RECORDS_PER_PAGE = 25

//const RECORDS_PER_PAGE = [25, 50, 100, 200, 500, 1000]

export const PUB_API_URL = "/data/publications/all.json"

function searchAuthors(q: string, publication: any) {
  for (let author of publication.authorList) {
    if (author.toLowerCase().includes(q)) {
      return true
    }
  }

  return false
}

export function search(query: any, publications: any[]): any[] {
  let ret: any = []

  let ql = query.text.toLowerCase()

  for (let publication of publications) {
    let found = false

    switch (query.field) {
      case "author":
        found = searchAuthors(ql, publication)
        break
      case "journal":
        found = publication.journal.toLowerCase() === ql
        break
      case "year":
        found = publication.year.toString() === ql
        break
      case "pmid":
        found = publication.pmid.toString() === ql
        break
      case "pmcid":
        found = publication.pmcid.toString() === ql
        break
      default:
        found = publication.pmid.toLowerCase().includes(ql)

        if (!found) {
          // try pmcid
          found = publication.pmcid.toLowerCase().includes(ql)
        }

        if (!found) {
          // try journal
          found = publication.title.toLowerCase().includes(ql)
        }

        if (!found) {
          // try journal
          found = publication.journal.toLowerCase().includes(ql)
        }

        if (!found) {
          // try authors
          found = searchAuthors(ql, publication)
        }

        // if (!found) {
        //   // try people
        //   for (let person of publication.peopleList) {
        //     //if (person.frontmatter.name.toLowerCase().includes(ql)) {
        //     if (person.toLowerCase().includes(ql)) {
        //       found = true
        //       break
        //     }
        //   }
        // }

        // if (!found) {
        //   // try id
        //   for (let person of publication.people) {
        //     if (person.frontmatter.personId.toLowerCase().includes(ql)) {
        //       found = true
        //       break
        //     }
        //   }
        // }

        if (!found) {
          // try year
          found = publication.year.toString().includes(ql)
        }

        break
    }

    if (found) {
      ret.push(publication)
    }
  }

  return ret
}

function booleanSearchAnd(s1: any, s2: any): any {
  const titles: Set<any> = new Set()

  s2.map((publication: any) => {
    titles.add(publication.title)
  })

  return s1.filter((publication: any) => {
    return titles.has(publication.title)
  })
}

function booleanSearchOr(s1: any, s2: any): any {
  const pubMap: any = {}

  s1.map((publication: any) => {
    if (!(publication.year in pubMap)) {
      pubMap[publication.year] = {}
    }

    if (!(publication.month in pubMap[publication.year])) {
      pubMap[publication.year][publication.month] = {}
    }

    pubMap[publication.year][publication.month][publication.title] = publication
  })

  s2.map((publication: any) => {
    if (!(publication.year in pubMap)) {
      pubMap[publication.year] = {}
    }

    if (!(publication.month in pubMap[publication.year])) {
      pubMap[publication.year][publication.month] = {}
    }

    if (!(publication.title in pubMap[publication.year][publication.month])) {
      pubMap[publication.year][publication.month][publication.title] =
        publication
    }
  })

  const ret = []

  for (let year of Object.keys(pubMap).sort().reverse()) {
    for (let month of Object.keys(pubMap[year]).sort().reverse()) {
      for (let title of Object.keys(pubMap[year][month])) {
        ret.push(pubMap[year][month][title])
      }
    }
  }

  return ret
}

function results(page: number, filteredPublications: any[]) {
  const x = page + 1
  const y = filteredPublications.length
  const suffix = filteredPublications.length !== 1 ? "results" : "result"

  if (y > x) {
    return `Page ${x} of ${y} ${suffix}`
  } else {
    return `${y} ${suffix}`
  }
}

export default function PublicationsPage({ location, data }: IDataPageProps) {
  const [publications, setPublications] = useState<any[]>([])

  const [journals, setJournals] = useState<any[]>([])

  const [authors, setAuthors] = useState<any[]>([])

  const [query, setQuery] = useState(EMPTY_QUERY)
  const [sortOrder, setSortOrder] = useState("Publication Date")
  //const [journals, setJournals] = useState<Array<[string, number]>>(useTopJournals(pubs))
  const [selectedJournals, setSelectedJournals] = useState<Set<string>>(
    new Set<string>()
  )

  const [selectedAuthors, setSelectedAuthors] = useState<Set<string>>(
    new Set<string>()
  )

  const [showAbstract, setShowAbstracts] = useState(false)
  const [instituteOnly, setInstituteOnly] = useState(true)
  const [firstAuthorOnly, setFirstAuthorOnly] = useState(true) //id === "all")
  const [descending, setDescending] = useState(true)

  const [filteredPublications, setFilteredPublications] = useState<any[]>([])

  const [pagePublications, setPagePublications] = useState<any[]>([])

  const [page, setPage] = useState(-1)
  const [pages, setPages] = useState(-1)

  //const [recordsPerPageIndex, setRecordsPerPageIndex] = useState(0)

  const [recordsPerPage, setRecordsPerPage] = useState(0)

  // const fetchData = async () => {
  //   const { data } = await axios.get(PUB_API_URL)
  //   setPublications(data)
  // }

  useEffect(() => {
    //fetchData()
    setPublications(data.all.publications)
  }, [])

  useEffect(() => {
    setAuthors(getTopAuthors(publications))
    setJournals(getTopJournals(publications))
  }, [publications])

  useEffect(() => {
    setPage(0)
    setPages(
      Math.floor(
        (filteredPublications.length + RECORDS_PER_PAGE - 1) / RECORDS_PER_PAGE
      )
    )

    setPagePublications(filteredPublications.slice(0, RECORDS_PER_PAGE))
  }, [filteredPublications])

  useEffect(() => {
    const s = page * RECORDS_PER_PAGE
    setPagePublications(filteredPublications.slice(s, s + RECORDS_PER_PAGE))
  }, [page])

  // useEffect(() => {
  //   setRecordsPerPage(RECORDS_PER_PAGE[recordsPerPageIndex])
  // }, [recordsPerPageIndex])

  useEffect(() => {
    if (query !== "") {
      updateFilteredPublications(
        getBooleanSearch(
          query,
          publications,
          search,
          booleanSearchAnd,
          booleanSearchOr
        )
      )
    } else {
      updateFilteredPublications(publications)
    }
  }, [
    query,
    instituteOnly,
    firstAuthorOnly,
    sortOrder,
    descending,
    recordsPerPage,
    selectedJournals,
    selectedAuthors,
    publications,
  ])

  // // If pubs are populated or user applies a filter, update
  // useEffect(() => {
  //   updatePublications()
  //   //setRecordsPerPage(RECORDS_PER_PAGE)
  // }, [
  //   pubs,
  //   selected,
  //   instituteOnly,
  //   firstAuthorOnly,
  //   sortOrder,
  //   descending,
  // ])

  function updateFilteredPublications(publications: any[]) {
    let pubs = publications

    // if (instituteOnly) {
    //   pubs = getInstitutePublications(pubs)
    // }

    // if (firstAuthorOnly) {
    //   pubs = useFirstAuthorPublications(pubs)
    // }

    if (selectedJournals.size > 0) {
      pubs = getJournalPublications(pubs, selectedJournals)
    }

    if (selectedAuthors.size > 0) {
      pubs = getAuthorPublications(pubs, selectedAuthors)
    }

    pubs = sortPublications(pubs, sortOrder, descending)

    setFilteredPublications(pubs)
  }

  function onSearch(text: string, clicked: boolean) {
    if (clicked) {
      setQuery(text)
    }
  }

  function onSortChange(value: string) {
    setSortOrder(value)
  }

  function onShowAbstractsChange(selected: boolean) {
    setShowAbstracts(selected)
  }

  function onFirstAuthorOnlyChange(selected: boolean) {
    setFirstAuthorOnly(selected)
  }

  function onPageChanged(page: number) {
    setPage(page - 1)
  }

  // const handleClick = (data: any) {
  //   setFilterYears(data)
  //   setPage(1)
  // }

  // const onShowMoreClick = (e: any) {
  //   // Increment to a different threshold each time
  //   setRecordsPerPageIndex(
  //     Math.min(recordsPerPageIndex + 1, RECORDS_PER_PAGE.length - 1)
  //   )
  // }

  function onJournalClick(journal: string, selected: boolean) {
    //setQuery(journal)

    let sj = new Set(selectedJournals)

    if (selected) {
      sj.add(journal)
    } else {
      sj.delete(journal)
    }

    setSelectedJournals(sj)
  }

  function onAuthorClick(author: string, selected: boolean) {
    //setQuery(journal)

    let sa = new Set(selectedAuthors)

    if (selected) {
      sa.add(author)
    } else {
      sa.delete(author)
    }

    setSelectedAuthors(sa)
  }

  //const hasSearchResults = query !== EMPTY_QUERY
  //let publications: any[] = hasSearchResults
  ///  ? filteredPublications
  // : pubs

  //let yearFilteredPublications = filteredPublications

  // if (filterYears.length > 0 && filterYears[0] !== -1) {
  //   yearFilteredPublications = publications.filter((publication: any) {
  //     return filterYears.includes(publication.year)
  //   })
  // } else {
  //   yearFilteredPublications = publications
  // }

  //const offset = (page - 1) * recordsPerPageIndex

  // let pagedPublications = filteredPublications.slice(
  //   offset,
  //   offset + recordsPerPage
  // )

  return (
    <BaseLayout
      title="Publications"
      location={location}
      headerChildren={
        <SearchBar
          onSearch={onSearch}
          placeholder="Search publications..."
          text={query}
          className="mx-auto mb-4 w-90/100 lg:w-1/2"
        />
      }
    >
      {/* <ArticleDiv className="py-8 xl:hidden">
        <></>
        <>
          <ThreeQuarterLayout>
            <SearchBar
              onSearch={onSearch}
              placeholder="Search publications..."
              text={query}
            />
            <></>
          </ThreeQuarterLayout>
        </>
        <></>
      </ArticleDiv> */}

      <ArticleDiv className="mt-4 mb-32">
        <></>
        <>
          {/* <SearchBar
            onSearch={onSearch}
            placeholder="Search publications..."
            text={query}
            className="w-full lg:hidden"
          /> */}
          <ThreeQuarterLayout className="mt-8 gap-16">
            <div>
              <PageTitle title="Publications" />
              <p className="my-8 text-sm text-gray-500">
                {results(page, filteredPublications)}
              </p>

              <Publications
                publications={pagePublications}
                showAbstract={showAbstract}
                showCount={false}
                baseMode={true}
                showMoreButton={false}
                expandAll={true}
              />

              {filteredPublications.length > RECORDS_PER_PAGE && (
                // <HCenterRow className="mt-8 mb-8 md:mb-0">
                //   <BlueButton
                //     ariaLabel={TEXT_SHOW_MORE}
                //     onClick={onShowMoreClick}
                //     className="font-semibold"
                //   >
                //     {TEXT_SHOW_MORE}
                //   </BlueButton>
                // </HCenterRow>

                <HCenterRow className="mt-16">
                  <Pagination
                    page={page + 1}
                    pages={pages}
                    onClick={onPageChanged}
                  />
                </HCenterRow>
              )}
            </div>

            <>
              <ToggleSwitch
                isSelected={showAbstract}
                onClick={onShowAbstractsChange}
                className="text-sm"
              >
                Show Abstracts
              </ToggleSwitch>
              <div className="mt-8 border-t border-gray-200 pt-2 text-sm">
                <VCenterRow className="justify-between">
                  <h2 className="font-semibold">Sort</h2>
                  <BaseRow className="overflow-hidden">
                    <button
                      aria-label="Sort ascending"
                      onClick={() => setDescending(!descending)}
                      className="color-ani flex h-6 w-6 flex-row items-center justify-center text-gray-900"
                    >
                      <FontAwesomeIcon
                        icon={
                          descending ? faArrowDownShortWide : faArrowUpShortWide
                        }
                        className="w-4"
                      />
                    </button>
                  </BaseRow>
                </VCenterRow>

                <SortOrder
                  onChange={onSortChange}
                  selected={sortOrder}
                  className="mt-4"
                />
              </div>

              {/* <TopPubs topPubs={topPubs} onPubClick={handlePubClick} /> */}
              <JournalFilter
                journals={journals}
                selected={selectedJournals}
                onClick={onJournalClick}
                max={8}
              />

              <AuthorFilter
                authors={authors.slice(0, 20)}
                selected={selectedAuthors}
                onClick={onAuthorClick}
                max={8}
              />
            </>
          </ThreeQuarterLayout>
        </>
        <></>
      </ArticleDiv>
    </BaseLayout>
  )
}
