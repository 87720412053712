import { useState } from "react"
import CheckBox from "../link/check-box"
import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { BUTTON_CLASSES } from "../link/button-link"
import cn from "../../lib/class-names"

import Button from "../link/button"
interface JournalProps {
  journal: [string, number]
  isSelected: boolean
  onClick: any
}

function Journal({ journal, isSelected, onClick }: JournalProps) {
  return (
    <li>
      <CheckBox
        onClick={() => onClick(journal[0], !isSelected)}
        isSelected={isSelected}
        className="truncate text-sm"
      >
        {journal[0]} ({journal[1]})
      </CheckBox>

      {/* <CheckBox onChange={() => onClick(journal[0], !selected)} selected={selected}>{`${useElipsis(journal[0])} (${journal[1]})`}</CheckBox> */}
    </li>
  )
}

interface JournalFilterProps {
  journals: [string, number][]
  selected: Set<string>
  onClick: any
  max: number
}

function JournalFilter({
  journals,
  selected,
  onClick,
  max = 10,
}: JournalFilterProps) {
  const [showAll, setShowAll] = useState(false)

  function onShowAll() {
    setShowAll(!showAll)
  }

  if (max > -1 && !showAll) {
    journals = journals.slice(0, max)
  }

  return (
    <div className="mt-8 border-t border-gray-200 pt-2 text-sm">
      {/* <ToggleSwitch
        isSelected={showAll}
        onClick={onShowAll}
        className="text-sm font-semibold"
      >
        Journals
      </ToggleSwitch> */}
      <button onClick={onShowAll} className="w-full text-left">
        <h2 className="font-semibold">Journals</h2>
      </button>
      <ul className="mt-2">
        {journals.map((journal: any, index: number) => {
          return (
            <Journal
              journal={journal}
              isSelected={selected.has(journal[0])}
              key={index}
              onClick={onClick}
            />
          )
        })}
      </ul>
      <Button
        onClick={onShowAll}
        ariaLabel="Show more items"
        className={cn(BUTTON_CLASSES, "w-full", [showAll, "rotate-180"])}
      >
        <FontAwesomeIcon icon={faChevronDown} className="w-4" />
      </Button>
    </div>
  )
}

export default JournalFilter
