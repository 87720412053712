import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../layouts/seo"
import IDataPageProps from "../interfaces/data-page-props"
import PublicationsPage from "../components/publication/publications-page"

export default function Page({ location, data }: IDataPageProps) {
  return <PublicationsPage location={location} data={data} />
}

export const Head = () => <Seo title="Publications" />

export const pageQuery = graphql`
  query {
    all: publicationsJson(personId: { eq: "all" }) {
      personId
      publications {
        title
        journal
        year
        doi
        pmid
        pmcid
        abstract
        authorList
      }
    }
  }
`

// headerImage: file(absolutePath: { regex: "/images/publications.webp/" }) {
//   name
//   ext
//   relativePath
//   childImageSharp {
//     gatsbyImageData(placeholder: BLURRED)
//   }
// }
