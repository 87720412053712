import { useState } from "react"
import CheckBox from "../link/check-box"
import * as React from "react"
import { BUTTON_CLASSES } from "../link/button-link"
import cn from "../../lib/class-names"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "../link/button"

interface JournalProps {
  journal: [string, number]
  selected: boolean
  onClick: any
}

function Journal({ journal, selected: isSelected, onClick }: JournalProps) {
  return (
    <li>
      <CheckBox
        onClick={() => onClick(journal[0], !isSelected)}
        isSelected={isSelected}
        className="truncate text-sm"
      >
        {journal[0]} ({journal[1]})
      </CheckBox>

      {/* <CheckBox onChange={() => onClick(journal[0], !selected)} selected={selected}>{`${useElipsis(journal[0])} (${journal[1]})`}</CheckBox> */}
    </li>
  )
}

interface AuthorFilterProps {
  authors: [string, number][]
  selected: Set<string>
  onClick: any
  max: number
}

function AuthorFilter({
  authors,
  selected,
  onClick,
  max = 10,
}: AuthorFilterProps) {
  const [showAll, setShowAll] = useState(false)

  function onShowAll() {
    setShowAll(!showAll)
  }

  if (max > -1 && !showAll) {
    authors = authors.slice(0, max)
  }

  return (
    <div className="mt-8 border-t border-gray-200 pt-2 text-sm">
      {/* <ToggleSwitch
        isSelected={showAll}
        onClick={onShowAll}
        className="font-semibold"
      >
        Authors
      </ToggleSwitch> */}
      <button onClick={onShowAll} className="w-full text-left">
        <h2 className="font-semibold">Authors</h2>
      </button>
      <ul className="mt-2">
        {authors.map((journal: any, index: number) => {
          return (
            <Journal
              journal={journal}
              selected={selected.has(journal[0])}
              key={index}
              onClick={onClick}
            />
          )
        })}
      </ul>
      <Button
        onClick={onShowAll}
        ariaLabel="Show more items"
        className={cn(BUTTON_CLASSES, "w-full", [showAll, "rotate-180"])}
      >
        <FontAwesomeIcon icon={faChevronDown} className="w-4" />
      </Button>
    </div>
  )
}

export default AuthorFilter
