/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import * as React from "react"
import { ReactNode } from "react"
//import Button from "../../components/button"
//import PublicationYears from "./publicationyears"
import { TEXT_SHOW_MORE } from "../../constants"
import HCenterRow from "../h-center-row"
import BlueButton from "../link/blue-button"
import BasePublicationList from "./base-publication-list"

const RECORDS_PER_PAGE = 25

interface IPublicationsProps {
  publications: any[]
  showAbstract?: boolean
  showCount?: boolean
  showMoreButton?: boolean
  expandAll?: boolean
  baseMode?: boolean
  onShowMoreClick?: any
  children?: ReactNode
}

function Publications({
  publications,
  showAbstract,
  showCount,
  showMoreButton,
  baseMode,
  expandAll = false,
  onShowMoreClick,
}: IPublicationsProps) {
  return (
    <>
      {publications.length > 0 && showCount && (
        <HCenterRow className="mb-8 justify-between">
          <div>
            {/* {`Showing ${Math.min(
              filteredPublications.length,
              recordsPerPage
            )} of ${publications.length} ${
              filteredPublications.length > 1 ? "publications" : "publication"
            }`} */}

            {`${publications.length} ${
              publications.length !== 1 ? "results" : "result"
            }`}
          </div>
        </HCenterRow>
      )}

      {publications.length > 0 && (
        <BasePublicationList
          publications={publications}
          showAbstract={showAbstract}
          expandAll={expandAll}
        />
      )}

      {showMoreButton && (
        <HCenterRow className="mt-8">
          <div>
            <BlueButton
              ariaLabel={TEXT_SHOW_MORE}
              onClick={onShowMoreClick}
              className="text-sm"
            >
              {TEXT_SHOW_MORE}
            </BlueButton>
          </div>
        </HCenterRow>
      )}
    </>
  )
}

export default Publications
