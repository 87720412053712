import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import cn from "../../lib/class-names"
import BlackLink from "../link/black-link"
import BlueLink from "../link/blue-link"
import { BUTTON_CLASSES } from "../link/button-link"
import * as React from "react"
import { ROUNDED_BUTTON_CLASSES } from "../link/rounded-button-link"
import IClassProps from "../../interfaces/class-props"

type AbstractProps = {
  publication: any
  expandAll: boolean
}

function Abstract({ publication, expandAll = false }: AbstractProps) {
  const [isExpanded, setExpanded] = useState(expandAll)

  return (
    <>
      <div className="mt-2 text-sm">
        <p className={cn("overflow-hidden", [!isExpanded, "h-0"])}>
          {publication.abstract}
        </p>

        <button
          aria-label="Show whole abstract"
          className={cn(BUTTON_CLASSES, ROUNDED_BUTTON_CLASSES, "w-full")}
          onClick={() => setExpanded(!isExpanded)}
        >
          <FontAwesomeIcon
            icon={faChevronDown}
            className={cn("w-3", [isExpanded, "rotate-180"])}
          />
        </button>

        {/* {!expanded && (
          <Row
            isCentered={true}
            className="absolute bottom-0 w-full h-full cursor-pointer hover:text-blue-500 trans-ani"
            style={{
              background:
                "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 40%, rgba(255,255,255,1) 100%)",
            }}
            onClick={() => setExpanded(true)}
          >
            <div>
              <FontAwesomeIcon icon="chevron-down" />
            </div>
          </Row>
        )} */}
      </div>

      {/* <Row
        isCentered={true}
        className="w-full cursor-pointer hover:text-blue-500 trans-ani"
        onClick={() => setExpanded(!expanded)}
      >
        <div>
          <FontAwesomeIcon icon={expanded ? "chevron-up" : "chevron-down"} />
        </div>
      </Row> */}

      {/* <RightDiv className="text-sm mt-1">
        <button
          className="text-gray-600 hover:text-default-blue trans-ani"
          onClick={() => setExpanded(!expanded)}
        >{`Read ${expanded ? "less" : "more"}`}</button>
      </RightDiv> */}
    </>
  )
}

function pubmedUrl(pubmed: number) {
  return `https://pubmed.ncbi.nlm.nih.gov/${pubmed}/`
}

function doiUrl(doi: string) {
  return `https://doi.org/${doi}`
}

function getUrl(publication: any) {
  if (publication.doi !== "") {
    return doiUrl(publication.doi)
  } else if (publication.pmid !== "") {
    return pubmedUrl(publication.pmid)
  } else {
    return ""
  }
}

interface BasePublicationProps extends IClassProps {
  index?: number
  publication: any
  showCount?: boolean
  showAbstract?: boolean
  expandAll?: boolean
  showDOI?: boolean
}

function BasePublication({
  index = -1,
  publication,
  showAbstract = false,
  showCount = false,
  expandAll = false,
  showDOI = true,
  className,
}: BasePublicationProps) {
  // const _handlePubClick = (journal: string) {
  //   if (onPubClick !== null) {
  //     onPubClick(journal)
  //   }
  // }

  //console.log(publication)

  const url = getUrl(publication)

  const authors = publication.authorList.join(", ")

  const links = []

  // links.push(
  //   <li key={links.length}>
  //     {publication.journal}. {publication.year}.
  //   </li>
  // )

  // if (showDOI && publication.doi !== "") {
  //   links.push(
  //     <li key={links.length}>
  //       {`DOI: `}
  //       <BlackLink
  //         ariaLabel="View article from DOI"
  //         href={doiUrl(publication.doi)}
  //       >
  //         {publication.doi}
  //       </BlackLink>
  //     </li>
  //   )
  // }

  if (publication.pmid && publication.pmid !== "") {
    links.push(
      <li key={links.length}>
        {`PMID: `}
        <BlackLink
          ariaLabel="View article from PubMed ID"
          href={pubmedUrl(publication.pmid)}
        >
          {publication.pmid}
        </BlackLink>
      </li>
    )
  }

  // if (publication.pmcid && publication.pmcid !== "") {
  //   links.push(
  //     <li key={links.length}>
  //       {`PMC: `}
  //       <BlackLink
  //         ariaLabel="View article from PubMed PMC ID"
  //         href={pubmedUrl(publication.pmcid)}
  //       >
  //         {publication.pmcid}
  //       </BlackLink>
  //     </li>
  //   )
  // }

  const title = publication.title

  return (
    <article className={cn("publication flex flex-row", className)}>
      {showCount && (
        <div className="mr-2 min-w-8 text-center font-normal text-gray-500">{`${
          index + 1
        }`}</div>
      )}
      <div>
        <ul className="mt-1 flex flex-row flex-wrap items-center gap-x-4 gap-y-1 text-xs text-gray-600">
          {links.map(link => link)}
        </ul>
        <h2 className="text-lg font-medium">
          {url !== "" ? (
            <BlueLink ariaLabel="View article" href={url} underline={true}>
              {title}
            </BlueLink>
          ) : (
            title
          )}
        </h2>
        <p className="text-sm">{authors}</p>
        {/* <p className="text-sm font-light capitalize text-green-600">
        {publication.journal}. {publication.year}.
      </p> */}

        {/* <ul className="mt-1 flex flex-row flex-wrap items-center gap-x-3 gap-y-1 text-sm text-gray-600">
          {links.map(link => link)}
        </ul> */}

        <p className="mt-1 text-sm text-gray-600">
          {publication.journal}. {publication.year}.
        </p>
        {showAbstract && publication.abstract !== "" && (
          <Abstract publication={publication} expandAll={expandAll} />
        )}
      </div>
    </article>
  )
}

export default BasePublication
