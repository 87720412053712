import * as React from "react"
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { KeyboardEvent, ChangeEvent, useEffect, useState } from "react"
import cn from "../../lib/class-names"
import IClassProps from "../../interfaces/class-props"
import VCenterRow from "../v-center-row"
import ArticleDiv from "../article-div"
import ThreeQuarterLayout from "../../layouts/three-quarter-layout"
import HCenterRow from "../h-center-row"

const H = "h-12"

interface ISearchButtonProps {
  globalHover: boolean
  onClick: any
}

function SearchButton({ globalHover, onClick }: ISearchButtonProps) {
  const [hover, setHover] = useState(false)

  function onMouseEnter() {
    setHover(true)
  }

  function onMouseLeave() {
    setHover(false)
  }

  return (
    <button
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      aria-label="Search"
      className={cn(
        "color-ani flex flex-row items-center justify-center py-2",
        [hover || globalHover, "text-blue-600", "text-blue-400"]
      )}
    >
      <FontAwesomeIcon icon={faSearch} size="1x" />
    </button>
  )
}

interface ClearButtonProps {
  onClick: any
  visible: boolean
}

function ClearButton({ onClick, visible }: ClearButtonProps) {
  const [hover, setHover] = useState(false)

  function onMouseEnter() {
    setHover(true)
  }

  function onMouseLeave() {
    setHover(false)
  }

  return (
    <button
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cn(
        `color-ani flex flex-row items-center justify-center py-2`,
        [hover, "text-gray-400", "text-gray-300"],
        [visible, "visible", "invisible"]
      )}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faTimes} size="lg" />
    </button>
  )
}

interface SearchBarProps extends IClassProps {
  text?: string
  placeholder?: string
  onSearch?: (text: string, clicked: boolean) => void
}

function SearchBar({
  text = "",
  placeholder = "Search items...",
  className,
  onSearch,
}: SearchBarProps) {
  const [hover, setHover] = useState(false)
  const [value, setValue] = useState("")

  useEffect(() => {
    setValue(text)
  }, [])

  useEffect(() => {
    setValue(text)
  }, [text])

  function onMouseEnter() {
    setHover(true)
  }

  function onMouseLeave() {
    setHover(false)
  }

  function onKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      if (onSearch) {
        onSearch(value, true)
      }
    }
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value)

    if (onSearch) {
      onSearch(e.target.value, e.target.value === "")
    }
  }

  function onClick() {
    if (onSearch) {
      onSearch(value, true)
    }
  }

  function onClear() {
    setValue("")

    if (onSearch) {
      onSearch("", true)
    }
  }

  return (
    <VCenterRow
      className={cn(
        `m-0 gap-x-3 overflow-hidden rounded-full border border-gray-200 px-4`,
        className
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <input
        type="text"
        aria-label="Search"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        className="grow bg-transparent text-sm outline-none"
      />

      <ClearButton onClick={onClear} visible={value !== ""} />
      <span
        className={cn("h-6 border-l border-gray-300", [
          value !== "",
          "visible",
          "invisible",
        ])}
      />

      <SearchButton globalHover={value !== ""} onClick={onClick} />
    </VCenterRow>
  )
}

export default SearchBar
